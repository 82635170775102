export enum FolderCategory {
  "analysis" = "analysis",
  "inspiration" = "inspiration",
}

export type FilesSortOrderComparisonReportEntry = {
  id: number;
  type: "creative_comparison_report";
  fileSortOrder: number;
};

export type FilesSortOrderReportingReportEntry = {
  id: number;
  type: "creative_reporting_report";
  fileSortOrder: number;
};

export type FilesSortOrderInspirationBoardEntry = {
  id: number;
  type: "inspiration_board";
  fileSortOrder: number;
};

export type FilesSortOrderTestingLogReportEntry = {
  id: number;
  type: "testing_log_report";
  fileSortOrder: number;
};

export type FilesSortOrderLaunchReportEntry = {
  id: number;
  type: "launch_report";
  fileSortOrder: number;
};

export type FilesSortOrderEntry =
  | FilesSortOrderComparisonReportEntry
  | FilesSortOrderReportingReportEntry
  | FilesSortOrderInspirationBoardEntry
  | FilesSortOrderTestingLogReportEntry
  | FilesSortOrderLaunchReportEntry;

export type FileType =
  | "creative_comparison_report"
  | "creative_reporting_report"
  | "inspiration_board"
  | "testing_log_report"
  | "launch_report";

export type FolderResponse = {
  id: number;
  uuid: string;
  title: string;
  folderSortOrder: number;
  createdAt: string;
  creator: number;
  clientId: number;
  isOpen: boolean;
  category: FolderCategory;
};

export type Folder = FolderResponse;
